const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyDpyOU4LwGtOj-tvPHs_LPO-WRgSNQVfIk",
    authDomain: "reach-services-network-dev.firebaseapp.com",
    projectId: "reach-services-network-dev",
    storageBucket: "reach-services-network-dev.appspot.com",
    messagingSenderId: "952908320310",
    appId: "1:952908320310:web:f0883ed990c8b958631297",
    measurementId: "G-XLQ6G2F244",
  },
  android_client_id:
    "952908320310-8e595k891tsv5tfejnj0r2i3ral4uuje.apps.googleusercontent.com",
  twitter_consumer_key: "OEkVQNIINislsIPeWN3PD3dvS",
  twitter_consumer_secret: "1Y5Y1bE269oBEJoXLo1GMnRWyrX353cvqLsOIDiU83g2qS2uV4",
  api_base_url: "https://api.reachdev.biz/api/",
  origin: "android.reachdev.biz",
};
export default environment;
